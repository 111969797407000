<template>
  <div id="app">
    <div id="hero">

      <div class="mb-8 mr-8">
        <img src="./assets/img/pear.png" alt="Black Pear Financial Services Logo" class="w-24 lg:w-auto">
      </div>
      <h1 class="mb-2">
        Black Pear
      </h1>
      <h1 class="">Financial Services</h1>
    </div>
    <div id="main">

      <div id="container" class="px-4">
        <div>
          <p class="text-4xl font-encode font-bold mb-8 mt-16">Our Services</p>

          <div>
            <h2>Digital Bookkeeping</h2>
            <p class="mt-4 text-xl font-encode font-light">Xero is market leading accounting software for small business.  We believe that the simplicity it provides in managing our client bookkeeping is unparalleled amongst its competitors.</p>
            <div class="three-grid">
              <div><p>Perfect For Payroll</p><img src="./assets/img/calc.png" alt=""></div>
              <div><p>Manage Expenses and Receipts</p><img src="./assets/img/receipt.png" alt=""></div>
              <div><p>Digital VAT Returns</p><img src="./assets/img/bills.png" alt=""></div>
            </div>
            <p class="text-xl font-encode font-light mt-16">We have built an excellent reputation taking care of a wide variety of tax and accounting needs for our valued clients. Whether you need assistance with tax filings or annual accounts or simply bookkeeping.</p>
            <p class="text-xl font-encode font-light mt-4">Black Pear Financial Services and Xero will help you:</p>
            <ul class="ml-4 mt-4 font-encode font-light text-lg">
              <li>Fully Automate Your Banking Connection</li>
              <li>Accept Payments Through Xero</li>
              <li>Manage Client Contacts</li>
              <li>With Management Reporting</li>
              <li>Send Invoices</li>
              <li>Create Quotes</li>
            </ul>

          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-900 w-full h-96"></div>
    <div class="mt-20 w-full bg-divider transform rotate-180 h-72 -mt-96"></div>
    <div class="bg-gray-900 w-full">
      <div class="container mx-auto px-4">
        <div class="text-white py-20">
          <h2 class="text-white text-4xl">Construction Sector Specialist</h2>
          <p class="mt-4 text-xl font-encode font-light">Black Pear was set up initially to service clients in the Construction Industry, giving us excellent knowledge of the accounting and tax requirements of working in this sector.</p>

          <div class="flex w-full justify-center items-center mt-40">
            <div class="w-full md:w-1/2 border border-gray-100 rounded p-8">
              <p class="font-encode font-bold text-3xl">Contact Us</p>
              <p class="mt-8 text-lg md:text-xl font-encode">
                <a href="mailto:contactus@blackpearfs.co.uk">contactus@blackpearfs.co.uk</a>
              </p>
              <p class="text-lg md:text-xl font-encode">37 Elm Green Close, Worcester, WR5 3HD</p>
            </div>
          </div>
        </div>
        <p class="w-full text-white text-center py-4">Registered Company Number: 10573471</p>

      </div>
    </div>


  </div>

</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app{
}

#hero {
  @apply flex text-4xl py-2 h-screen flex justify-center items-center flex-col bg-gray-900
}

#main {
  @apply bg-divider  w-full bg-top -mt-24 lg:-mt-48 pt-36 bg-no-repeat
}

#main #container{
  @apply container mx-auto
}

#main h2{
  @apply text-4xl
}

h1{
  @apply text-white font-encode
}

h2{
  @apply text-gray-900 font-encode
}

.three-grid{
  @apply grid grid-cols-12 mt-12 mb-12 gap-4 lg:gap-20
}

.three-grid div{
  @apply col-span-12 md:col-span-4 flex flex-col justify-center items-center border border-gray-200  rounded py-4
}

.three-grid p{
  @apply text-xl lg:text-2xl mb-4 w-56 text-center font-encode font-semibold h-16 lg:h-24
}

li{
  @apply list-disc ml-4
}
</style>
